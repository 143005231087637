<template>
  <div class="home-container">
    <div class="info-container">
      <PowerBI :url="url" />
    </div>
  </div>
</template>

<script>
import PowerBI from './PowerBI';
export default {
  components: { PowerBI },
  computed: {
    url() {
      return this.$store.getters['configuration/value']('powerBI.BureauDeWit.URL');
    }
  }
};
</script>
